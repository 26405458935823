<template>
    <div class="pop flex items-center justify-center min-h-screen bg-gray-100">
        <span class="logo">
            <router-link to="/"> <img loading="lazy" decoding="async" src="../assets/Images/go_logo.svg" alt="Recruitable" width="150" height="100" class="thelogo" /></router-link>
        </span>
        <div class="bg-white shadow-card rounded-md p-8 w-2/5">
            <div v-if="pass">
                <div style="font-weight: bold">Please enter your Email to pass the test</div>
                <div class="input_group">
                    <input type="text" id="log_email" name="log_email" v-model="email" required />
                    <label for="log_email" class="required floating_label" :class="{ active: email }"><font-awesome-icon :icon="['far', 'envelope']" class="icon" /> {{ $t("Email") }}</label>
                    <span v-if="emailError" class="err_msg">{{ emailError }}</span>
                </div>
                <div style="margin-top: 50px; text-align: right">
                    <button style="padding: 10px 20px; cursor: pointer; background-color: #00aef0; color: white; font-weight: 700; border-radius: 8px" @click="check" :disabled="isButtonDisabled">
                        Next
                    </button>
                </div>
            </div>
            <div v-else class="">
                <div style="margin-bottom: 50px; font-weight: bold">Congratulation Now you can pass the test</div>
                <div class="flex items-center justify-center" style="text-align: center"><img :src="require(`../assets/stati3.png`)" style="width: 30%" /></div>
                <div style="margin-top: 50px; text-align: right">
                    <a :href="link" style="padding: 10px 20px; cursor: pointer; background-color: #00aef0; color: white; font-weight: 700; border-radius: 8px" @click="check">Pass assessment</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "InviteC",
    data() {
        return {
            emailError: "",
            pass: true,
            isButtonDisabled: false,
            token: null,
            email: "",
            link: "",
        };
    },
    methods: {
        check() {
            let isValid = true;
            const emailRegex = /^\S+@\S+\.\S+$/; // Regular expression for basic email format
            this.isButtonDisabled = true;
            if (!emailRegex.test(this.email)) {
                this.emailError = "Please enter a valid email address.";
                isValid = false; // Update the formValid flag

                this.isButtonDisabled = false;
            } else {
                this.emailError = "";
                this.pass = true;
                isValid = true;
                this.isButtonDisabled = true;
            }
            this.token = this.$route.query.token;
            if (isValid) {
                this.isButtonDisabled = true;

                axios
                    .post(
                        "https://server.go-platform.com/inviteCandidate/generate2/?token=" + this.token,
                        { email: this.email },
                        {
                            withCredentials: true,
                        },
                    )
                    .then((response) => {
                        console.log(response);

                        if (response.data.message == "Invalid token!") {
                            alert("link has expired");
                        } else {
                            this.link = response.data.message;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.pass = true;
                        this.isButtonDisabled = false;
                    });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.pop {
    height: 100vh;
    width: 100%;
    display: flex;
    position: fixed;
    background: #f8f8fa;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.input_group {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding: 0;
    margin-top: 20px;
    position: relative;

    label {
        display: flex;
        font-weight: 500;
        font-size: 18px;
        line-height: 12px;
        align-items: center;
        margin-bottom: 5px;
    }

    .required {
        &::after {
            content: "*";
            color: #ff6969;
        }
    }

    .err_msg {
        color: #ff6969;
        font-size: 14px;
        font-weight: 300;
        position: absolute;
        top: 100%;
    }

    .floating_label {
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: 5px;
        padding: 0 1% 0 0;
        transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
        transition: all 0.3s ease;

        .icon {
            margin-right: 10px;
            color: #2196f3;
        }
    }

    .floating_label.active {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        background: #fff;

        transform: translateY(-170%);
    }

    input:focus + .floating_label {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        background: #fff;
        transform: translateY(-170%);
    }

    input {
        border: 1px solid #e5e5ef;
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 4px;
        width: 100%;
        height: 2.5rem;
        padding: 0 0.5rem;
        font-size: 16px;
    }

    div {
        display: flex;
        align-items: center;
        border: 1px solid #e5e5ef;
        height: 2.5rem;
        border-radius: 4px;

        button {
            width: auto;
            height: fit-content;
            background: none;
        }

        input {
        }
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        margin-top: 0;
    }
}

.logo {
    font-weight: 900;
    font-size: 40px;
    line-height: 18px;
    color: #2196f3;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 1rem 0 3rem;
    gap: 0.5rem;
    position: absolute;
    top: 1%;
    left: 0;
    padding: 15px;

    @media (min-width: 768px) and (max-width: 991.98px) {
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 32px;
    }
}
</style>
