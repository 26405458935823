<template>
    <div class="pop_up justify-center lg:justify-between">
        <div class="login-container relative">
            <!--   -->
            <span class="logo">
                <router-link to="/"> <img loading="lazy" decoding="async" src="../assets/Images/go_logo.svg" alt="Recruitable" width="150" height="100" class="thelogo" /></router-link>
            </span>
            <!--  <button
                class="w-5 h-5 border absolute top-3 right-1 border-gray-400 rounded-full bg-white text-gray-400 flex justify-center items-center p-5"
                @click="() => {
                    this.$router.back();
                }
                    ">
                <font-awesome-icon :icon="['fas', 'x']" />
            </button>-->
            <Transition name="slide-fade">
                <div class="log_in" v-if="!psswrd_forgot">
                    <!-- <form action="POST" @submit.prevent> -->
                    <span class="login-span">{{ $t("Log in to your account") }}</span>
                    <span class="subLogin">Please log in to continue to your account.</span>

                    <div class="input_group">
                        <input type="text" id="log_email" name="log_email" v-model="email" required />
                        <label for="log_email" class="required floating_label" :class="{ active: email }"><font-awesome-icon :icon="['far', 'envelope']" class="icon" /> {{ $t("Email") }}</label>
                        <span v-if="requiredFields.email" class="err_msg">{{ requiredFields.email }}</span>
                    </div>

                    <div class="input_group pad_right_0">
                        <!-- <label for="password">{{ $t("Password") }} </label> -->
                        <!-- <div>
                                <input :type="showPassword ? 'text' : 'password'" name="password" id="password" v-model="password" placeholder="*********" /><button
                                    @click.prevent="showPassword = !showPassword"
                                >
                                    <img loading="lazy"  decoding="async" class="eye-icon" :src="require(`../assets/${showPassword ? 'icon_eye_closed.svg' : 'icon_eye.svg'}`)" width="25" height="25" alt="" />
                                </button>
                            </div> -->
                        <div class="input_group">
                            <input :type="showPassword ? 'text' : 'password'" id="password" name="password" v-model="password" />
                            <label for="password" class="required floating_label" :class="{ active: password }"><font-awesome-icon :icon="['fas', 'lock']" class="icon" /> {{ $t("Password") }}</label
                            ><button class="absolute right-2 top-[50%]" style="transform: translateY(-50%)" @click.prevent="showPassword = !showPassword">
                                <img
                                    loading="lazy"
                                    decoding="async"
                                    class="eye-icon"
                                    :src="require(`../assets/${showPassword ? 'icon_eye_closed.svg' : 'icon_eye.svg'}`)"
                                    width="25"
                                    height="25"
                                    alt=""
                                />
                            </button>
                            <span v-if="requiredFields.password" class="err_msg">{{ requiredFields.password }}</span>
                        </div>
                        <button class="forgot-pass" @click.prevent="psswrd_forgot = !psswrd_forgot">
                            {{ $t("Forgot Password?") }}
                        </button>
                    </div>

                    <button v-if="!loading" type="submit" @click="login" value="Log in" class="loginBtn">{{ $t("Log in") }}</button>
                    <button v-else value="Log in" class="loginBtn loading-btn">
                        <LoaderComponent />
                    </button>
                    <p v-if="wrongCredentials" class="text-left text-red-500" style="font-size: 14px">{{ this.errMsg }}</p>
                    <!-- </form> -->
                    <span class="sign_up"
                        >{{ $t("You don't have an account ?") }}
                        <router-link to="/register">{{ $t("Sign Up") }}</router-link>
                    </span>
                    <!-- <GoogleLogin :callback="callback" class="mb-5">
                        <button>Login Using Google</button>
                    </GoogleLogin> -->
                </div>
            </Transition>
            <Transition name="slide-fade">
                <div class="log_in" v-if="psswrd_forgot">
                    <form action="" @submit.prevent="resetPassword">
                        <span>{{ $t("Forgot Password?") }}</span>
                        <div class="input_group relative">
                            <label for="email_forgot">{{ $t("Email Address") }}</label>
                            <input type="email" name="email_forgot" id="email_forgot" v-model="resetPassEmail" :placeholder="$t('Fill in your Email')" />
                            <span v-if="resetEmailRequired" class="err_msg">{{ resetEmailRequired }}</span>
                        </div>
                        <div class="captcha">
                            <VueRecaptcha :sitekey="sitekey" size="normal" badge="bottomright" :loadRecaptchaScript="true" ref="recaptcha" @verify="onVerify"> </VueRecaptcha>
                        </div>
                        <div class="btns">
                            <button @click.prevent="psswrd_forgot = !psswrd_forgot">{{ $t("Cancel") }}</button>
                            <button v-if="!loading" @click="resetPassword">{{ $t("Next") }}</button>

                            <button v-else value="Log in" class="loginBtn loading-btn">
                                <LoaderComponent />
                            </button>
                        </div>
                        <p v-if="resetPasswordMessage" class="text-right text-green-500" style="font-size: 14px">{{ this.resetPasswordMessage }}</p>
                    </form>
                    <a class="no_access" href="">{{ $t("Don't have access to these informations?") }}</a>
                </div>
            </Transition>
        </div>
        <div class="imgContainer hidden lg:block" v-if="this.screenWidth > 768">
            <img v-if="!imgLoaded" class="h-full absolute z-[10] top-0 bottom-0 right-0" src="../assets/landing-page-logos/lowSizeLoginImg.png" alt="" />
            <img
                class="h-full absolute top-0 bottom-0 right-0"
                src="../assets/landing-page-logos/loginImage.png"
                @load="
                    () => {
                        this.imgLoaded = true;
                        console.log('Login Image Loaded');
                    }
                "
                alt=""
            />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";
import { useStore } from "../store/index";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { decodeCredential, googleTokenLogin } from "vue3-google-login";

export default {
    name: "LogIn",
    components: { VueRecaptcha, LoaderComponent },
    props: {
        show: Boolean,
        // toggleModal: Function,
        userLoggedIn: Function,
    },
    data() {
        return {
            showPassword: false,
            screenWidth: window.innerWidth,
            psswrd_forgot: false,
            sitekey: "6LeAEmcnAAAAAERPmvDElaSYYb9Zyw7YP-W_MOAu",
            email: "",
            password: "",
            loading: false,
            wrongCredentials: false,
            errMsg: "",
            resetPassEmail: "",
            resetEmailRequired: "",
            resetPasswordMessage: "",
            requiredFields: {
                email: "",
                password: "",
            },
            imgLoaded: false,
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        closepop() {},
        callback(response) {
            googleTokenLogin().then((response) => {
                console.log("Handle the response", response);
            });
            console.log("google res", response);
            const userData = decodeCredential(response.code);
            console.log("Handle the response", userData);
        },
        toggleModal() {
            this.$router.push("/login");
        },
        resetPassword() {
            console.log("reset password");
            this.resetEmailRequired = "";
            if (this.resetPassEmail) {
                const emailRegex = /^\S+@\S+\.\S+$/; // Regular expression for basic email format

                if (!emailRegex.test(this.resetPassEmail)) {
                    this.resetEmailRequired = "Please enter a valid email address.";
                } else {
                    this.loading = true;
                    this.resetEmailRequired = "";

                    axios
                        .post(
                            "https://server.go-platform.com/user/changePassword",
                            { email: this.resetPassEmail },
                            {
                                withCredentials: true,
                            },
                        )
                        .then((response) => {
                            console.log(response);
                            this.resetPasswordMessage = response.data.message;
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.log(error);
                            this.loading = false;
                            this.resetPasswordMessage = error.response.data.message;
                        });
                }
            }
        },
        checkInputs() {
            this.requiredFields = {
                email: "",
                password: "",
            };
            let isValid = true;
            // const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/;

            // if (!passwordRegex.test(this.password)) {
            //     this.requiredFields.password = "Password must contain at least one uppercase letter, one special character, and be at least 8 characters long.";
            //     isValid = false; // Update the formValid flag
            // }
            const emailRegex = /^\S+@\S+\.\S+$/; // Regular expression for basic email format

            if (!emailRegex.test(this.email)) {
                this.requiredFields.email = "Please enter a valid email address.";
                isValid = false; // Update the formValid flag
            }
            console.log({ isValid, requiredFields: this.requiredFields });
            return isValid;
        },

        login() {
            if (!this.checkInputs()) {
                return;
            }
            this.loading = true;
            let user = {
                email: this.email,
                password: this.password,
            };
            axios
                .post("https://server.go-platform.com/user/signin", user, { withCredentials: true })
                .then((res) => {
                    console.log("res LOOOOOOg", res);
                    if (res.status === 200) {
                        console.log("res", res);
                        this.Store.isLoading = true;
                        console.log("normally it s logged", res.status);
                        this.$router.push(`/dashboard?company_name=${res.data.company_name}`);
                        // this.userLoggedIn();
                        this.Store.userLogged();
                        this.Store.getCompanyCredit();
                        if (res.data.role) {
                            this.$router.push(`/adminBlogs?company_name=${res.data.admin_name}`);
                        } else {
                            this.$router.push(`/dashboard?company_name=${res.data.company_name}`);
                        }

                        // this.toggleModal();
                        // this.$emit("loggedIn");
                    }
                })
                .then(() => {
                    console.log("he should be logged");
                    axios
                        .get("https://server.go-platform.com/user/isLoggedIn", {
                            withCredentials: true,
                        })
                        .then((response) => {
                            console.log("Response:", response);
                            this.$gtag.event("login", { method: "Google" });
                        })
                        .catch((error) => {
                            console.error("Error:", error);
                        });
                })
                .catch((err) => {
                    this.wrongCredentials = true;
                    this.errMsg = err.response.data.message;
                    console.log("THE Error is here ---------------------", err);
                })
                .finally(() => (this.loading = false));
        },
        async forgotPassword(response) {
            const recaptchaToken = response;

            // Add reCAPTCHA token to your API request payload
            const forgotPasswordData = {
                email: this.email,
                recaptchaToken, // Add the reCAPTCHA token here
            };

            // Now you can make an API request to your backend to verify the reCAPTCHA token and handle the forgot password process.
            // For example, using Axios:
            try {
                const response = await axios.post("https://server.go-platform.com/api/verify-recaptcha", forgotPasswordData);
                console.log(response.data); // Handle the response from the backend
            } catch (error) {
                console.error("Error:", error);
            }
        },
        onVerify(response) {
            // The reCAPTCHA response is passed as a parameter to the event handler
            this.forgotPassword(response);
        },
    },
    watch: {
        show() {
            this.psswrd_forgot = false;
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.pop_up {
    height: 100vh;
    width: 100%;
    display: flex;
    position: fixed;
    background: #fff;
    // align-items: center;
    // justify-content: space-between;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    .login-container {
        background: #fff;
        width: 40%;
        margin: 0 6%;
        // padding: 1rem 2rem;
        border-radius: 20px;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        position: relative;

        @media (min-width: 768px) and (max-width: 991.98px) {
            width: 50%;
        }

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
            width: 80%;
            height: 60%;
        }

        .log_in {
            width: 60%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            position: absolute;
            bottom: 15%;
            top: 20%;

            form {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 1rem;
                color: #5a5a5d;

                /*>:nth-child(5){
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 2rem 0 0;
                }*/
                .captcha {
                    padding: 1rem;

                    > :first-child {
                        > :first-child {
                            width: 100% !important;
                        }
                    }
                }
            }

            .sign_up {
                font-weight: 400;
                font-size: 16px;
                line-height: 100%;
                color: #4a5568;
            }

            @media (min-width: 768px) and (max-width: 991.98px) {
            }

            @media (max-width: 767.98px) {
                /* Your CSS styles for smartphones go here */
                gap: 2rem;
                width: 100%;
            }
        }
    }

    .imgContainer {
        width: 40%;
        height: 100%;
        // background-image: url("../assets/landing-page-logos/loginImage.svg");
        // background-size: cover;
        // background-repeat: no-repeat;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
    }
}

.exit_zone {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.logo {
    font-weight: 900;
    font-size: 40px;
    line-height: 18px;
    color: #2196f3;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 1rem 0 3rem;
    gap: 0.5rem;
    position: absolute;
    top: 1%;
    left: 0;

    @media (min-width: 768px) and (max-width: 991.98px) {
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        font-size: 32px;
    }
}

.login-span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    color: #263238;
    text-align: left;
    font-size: 36px;
    margin-bottom: 0;
}

.subLogin {
    color: #969696;
    font-size: 14px;
    text-align: left;
}

a {
    font-size: 18px;
}

input[type="submit"] {
    color: #fff;
    background: #2196f3;
    border-radius: 7px;
    border: none;
    width: 100%;
    padding: 1rem;
    font-weight: 600;
    font-size: 19.96px;
    line-height: 100%;
}

button {
    color: #2196f3;
    background: none;
    border: none;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

button:hover {
    opacity: 0.85;
}

.font-icon {
    width: 13px;
    margin-left: 5px;
}

.font-icon-two {
    width: 12px;
    margin-left: 5px;
    margin-bottom: 2px;
}

.input_group {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding: 0;
    margin-top: 20px;
    position: relative;

    label {
        display: flex;
        font-weight: 500;
        font-size: 18px;
        line-height: 12px;
        align-items: center;
        margin-bottom: 5px;
    }

    .required {
        &::after {
            content: "*";
            color: #ff6969;
        }
    }

    .err_msg {
        color: #ff6969;
        font-size: 14px;
        font-weight: 300;
        position: absolute;
        top: 100%;
    }

    .floating_label {
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: 5px;
        padding: 0 1% 0 0;
        transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
        transition: all 0.3s ease;

        .icon {
            margin-right: 10px;
            color: #2196f3;
        }
    }

    .floating_label.active {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        // width: 10%;
        background: #fff;

        transform: translateY(-170%);
    }

    input:focus + .floating_label {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        // width: 10%;
        background: #fff;
        transform: translateY(-170%);
    }

    input {
        border: 1px solid #e5e5ef;
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 4px;
        width: 100%;
        height: 2.5rem;
        padding: 0 0.5rem;
        font-size: 16px;
    }

    div {
        display: flex;
        align-items: center;
        border: 1px solid #e5e5ef;
        height: 2.5rem;
        border-radius: 4px;

        button {
            width: auto;
            height: fit-content;
            background: none;
        }

        input {
            // border: none;
        }
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
        margin-top: 0;
    }
}

.forgot-pass:hover {
    text-decoration: underline;
}

.forgot-pass {
    font-family: "Roboto";
    font-size: 16px;
}

.pad_right_0 {
    padding-right: 0;
}

.marge_3_0 {
    margin: 3rem 0;
}

.btns {
    display: flex;
    flex-flow: nowrap row;
    gap: 1.5rem;
    justify-content: flex-end;
    padding: 0 1rem;
    align-items: center;

    button {
        font-size: 21px;
        font-weight: 600;
        line-height: 20px;
        padding: 3% 0;
        width: 25%;
        justify-content: center;
    }

    > :first-child {
        background: none;
    }

    > :nth-child(2) {
        color: #fff;
        background: #2196f3;
        border-radius: 7px;
    }
}

.OR {
    color: #2196f3;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3%;
}

a {
    text-decoration: none;
    color: #2196f3;
}

.thelogo {
    margin-top: 20px;
}

.no_access {
    font-weight: 400;
    font-size: 19.96px;
    line-height: 100%;
    color: #bebebf;
}

.slide-fade-enter-active {
    transition: all 0.6s ease-in;
}

.slide-fade-leave-active {
    display: none;
    transition: all 0.4s ease cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter {
    transform: translateX(0);
    opacity: 0;
}

.eye-icon {
    width: 30px;
    padding: 0 3px;
}

.slide-fade-leave-to {
    transform: translateX(0);
    opacity: 0;
}

a:hover {
    text-decoration: underline;
}

.loginBtn {
    cursor: pointer;
    transition: 0.2s ease-in;
    background-color: #2196f3;
    color: white;
    width: 100%;
    padding: 12px;
    justify-content: center;
    border-radius: 5px;
    font-size: 20px;
    font-family: "Roboto";
    height: 50px;
    font-weight: 600;

    &:hover {
        opacity: 0.85;
    }
}

.loading-btn {
    opacity: 0.85;
    align-items: center;
}

// @media (min-width: 1230px) {
//     .pop_up > .login-container {
//         width: 450px;
//     }
// }

.err_msg {
    color: #ff6969;
    font-size: 14px;
    font-weight: 300;
    position: absolute;
    top: 100%;
    text-align: left;
}
</style>
